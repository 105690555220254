import { useCloudApi } from '@/composables/cloudApi'
import type { V1alpha1PoolOption, V1alpha1PoolRef } from '@streamnative/cloud-api-client-typescript'
import type { AxiosError } from 'axios'
import { i18n } from '@/lang'
import type { PricePackage } from '@/data/pricePackages'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const { t } = i18n.global
const billingQueryStrategy = 'annotations.cloud.streamnative.io/billing-query-strategy'
export type DeploymentType = Exclude<keyof PricePackage, 'name'>

export const fetchOrganization = async (namespace: string | undefined) => {
  if (namespace === undefined) {
    throw Error(getErrorMessage('getOrganization error'))
  }

  try {
    const api = useCloudApi()
    return await api.readOrganization(namespace)
  } catch (e) {
    if ((e as AxiosError).response?.status === 403) {
      // When organization is not ready, it also throws 403.
      throw Error(t(`organization.notification.notReady`))
    }
    throw Error(getErrorMessage(e, 'getOrganization error'))
  }
}

export interface Location {
  location: string
  name: string
  label: string
  pool: Pool
}
export interface Pool {
  cloudType: string
  name: string
  namespace: string
  label: string
  poolRef: V1alpha1PoolRef
  deploymentType: DeploymentType
  deploymentTypeLabel: 'Hosted' | 'BYOC'
  features?: { [key: string]: boolean }
}

const getDeploymentType = (poolOption: V1alpha1PoolOption): DeploymentType => {
  if (poolOption.metadata?.annotations?.[billingQueryStrategy]) {
    return poolOption.metadata?.annotations?.[billingQueryStrategy] as DeploymentType
  }
  if (poolOption.spec?.deploymentType) {
    return poolOption.spec.deploymentType as DeploymentType
  }
  if (poolOption.spec?.poolRef.namespace === 'streamnative') {
    return 'hosted'
  }
  return 'managed'
}

export const getLocationsAndPools = async (organization: string) => {
  const locations: Location[] = []
  const pools: Pool[] = []
  const api = useCloudApi()
  const { data } = await api.listNamespacedPoolOption(organization)

  for (let i = 0; i < data.items.length; i++) {
    const item = data.items[i]
    if (!item.spec) {
      throw Error(t('dashboard.errorGettingLocations'))
    }
    if (item.spec.locations.length === 0) {
      continue
    }
    const deploymentType = getDeploymentType(item)

    const pool: Pool = {
      cloudType: item.spec?.cloudType,
      namespace: item.spec?.poolRef.namespace,
      name: item.spec?.poolRef.name,
      label: `${item.spec?.cloudType}/${deploymentType} - ${item.spec?.poolRef.namespace}/${item.spec?.poolRef.name}`,
      poolRef: item.spec?.poolRef,
      deploymentType,
      deploymentTypeLabel: deploymentType === 'hosted' ? 'Hosted' : 'BYOC',
      features: item.spec?.features
    }
    pools.push(pool)
    for (let j = 0; j < item.spec.locations.length; j++) {
      const location: Location = {
        location: item.spec?.locations[j].location,
        name: item.spec?.locations[j].location,
        label: `${pool.label}/${item.spec?.locations[j].location}`,
        pool
      }
      locations.push(location)
    }
  }
  return { locations, pools }
}
